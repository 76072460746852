<template>
  <div class="mission-add">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/mission'>宣教管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'宣教'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
    <el-form
      :rules="dataRules"
      label-width="100px"
      :model="form"
      ref="form"
      v-loading="dataListLoading"
      :class="form.type == '' || form.type == 1 || form.type == 2 ? 'form-con-center':''"
    >
      <el-form-item label="宣教名称" prop="workName">
        <el-input placeholder="请输入宣教名称" v-model="form.workName" class="wd400"></el-input>
      </el-form-item>
      <el-form-item label="宣教分类" prop="workType">
        <!-- <el-select
              placeholder="请选择宣教分类"
              v-model="form.workType"
              clearable
              class='wd400'
              >
                  <el-option
                  v-for="(item,index) in typeList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                  ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二级分类" prop="workTypeSec">
          <el-select v-model="form.workTypeSec" class='wd400' placeholder="请选择二级分类">
            <el-option
              v-for="(item,index) in secList"
              :key="index"
              :value="item.value"
              :label="item.name"
            >{{item.name}}</el-option>
        </el-select>-->
        <el-cascader
          class="wd400"
          v-model="form.workType"
          :options="options"
          @change="handleChange"
          :props="{ checkStrictly: true,value:'value',label:'name'}"
          filterable
          :show-all-levels="false"
          clearable
        ></el-cascader>
      </el-form-item>
      <!-- <el-form-item label="对应病种" prop='workEnertyType'> 
              <el-select
              placeholder="请选择对应病种"
              v-model="form.workEnertyType"
              clearable
              class='wd400'
              >
                  <el-option
                  v-for="(item,index) in diseasesList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                  ></el-option>
              </el-select>
      </el-form-item>-->
      <!-- <el-form-item >
                <el-radio v-model="radio" label="3">富文本</el-radio>
                <el-radio v-model="radio" label="1">视频宣教</el-radio>
                <el-radio v-model="radio" label="2">音频宣教</el-radio>
      </el-form-item>-->
      <el-form-item label="内容形式" prop="type">
        <el-select placeholder="请选择内容形式" v-model="form.type" clearable class="wd400">
          <el-option
            v-for="(item,index) in [{name:'视频',value:1},{name:'音频',value:2},{name:'富文本',value:3}]"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="宣教内容" prop="workContent" v-loading="upLoading" v-if="form.type">
        <!-- 123 -->
        <tiny-mce v-model="form.workContent" v-if="form.type==3"></tiny-mce>
        <template v-if="form.type==1" v-loading="upLoading">
          <div>
            <!-- v-bind:data="{step:'image',token:this.token}" -->
            <el-upload
              class="upload-demo wd400"
              drag
              action="123"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :show-file-list="false"
            >
              <div v-if="form.mvUrl" style="width: 100%; height: 100%">
                <img
                  :src="form.mvUrl ? imageUrl : '~@/assets/img/mv.png'"
                  style="width: 100%; height: 100%"
                >
              </div>
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
              </div>
              <div class="el-upload__tip" slot="tip">仅支持MP4格式的视频文件
                <el-button type="primary" @click="previewVideo">预览视频</el-button>
              </div>
            </el-upload>
          </div>
        </template>
        <template v-if="form.type==2" v-loading="upLoading">
          <div>
            <!-- v-bind:data="{step:'image',token:this.token}" -->
            <el-upload
              class="upload-demo wd400"
              drag
              action="123"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              :show-file-list="false"
            >
              <div v-if="form.mvUrl" style="width: 100%; height: 100%">
                <img src="../../../assets/img/mv.png" style="width: 100%; height: 100%">
              </div>
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
              </div>
              <div class="el-upload__tip" slot="tip">仅支持MP3格式的音频文件
                <el-button type="primary" @click="previewVideo">预览音频</el-button>
              </div>
            </el-upload>
          </div>
        </template>
      </el-form-item>
      <el-form-item style="padding-top: 40px;" class="last-el-form-item form-con-center">
        <el-button type="primary" @click="submit" v-points>保存</el-button>
        <el-button @click="back">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" width="50%" destroy-on-close>
      <div id="abc" class="video-js"></div>
    </el-dialog>
    <!-- <el-dialog :visible.sync="dialogVisible" append-to-body>
              <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>-->
  </div>
</template>
<script>
import TinyMce from "@/components/tiny-mce";
import Qs from "qs";
import axios from "axios";
export default {
  name: "missionAdd",

  components: {
    TinyMce,
  },

  data() {
    return {
      dialogVisible: false,
      edit: false,
      radio: "1",
      imageUrl: "",
      form: {
        mvUrl: "",
        workName: "",
        workType: "",
        workEnertyType: "",
        workContent: "",
        type: "",
        workTypeSec: "",
      },
      upLoading: false,
      diseasesList: [],
      typeList: [],
      dataRules: {
        workName: [
          { required: true, message: "宣教名不能为空", trigger: "blur" },
        ],
        workType: [
          { required: true, message: "请选择宣教类型", trigger: "change" },
        ],
        // workEnertyType: [{ required: true, message: "请选择病种类型", trigger: "change" }],
        workContent: [
          { required: true, message: "宣教内容不能为空", trigger: "blur" },
        ],
        type: [
          { required: true, message: "内容形式不能为空", trigger: "blur" },
        ],
      },
      dataListLoading: false,
      secList: [],
      options: [],
    };
  },

  watch: {
    // 'form.workType':function(val){
    //   console.log(val,'val',this.typeList);
    //   if(val){
    //     let name =this.typeList.filter(v=>{
    //     if(v.value==val){
    //       console.log(v);
    //       return v
    //     }
    //   })[0].name
    //   this.getDictList2(name)
    //   }
    // }
  },

  async mounted() {
    let query = this.$route.query;
    await this.getTypeList();
    if (query.id) {
      this.edit = true;
      this.dataListLoading = true;
      await this.init(query.id);
    }
    await this.getDiseasesList();
  },

  methods: {
    handleChange(v) {
      console.log(v, "v");
    },
    // 获取二级分类
    getDictList2(dict) {
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let _this = this;
      _this
        .$http({
          url: _this.$http.adornUrl("/dict/getSysDicts?type=" + dict),
          method: "get",
          params: {
            pageSize: 999,
            teamsId,
          },
        })
        .then(({ data }) => {
          if (data.status) {
            _this.secList = data.data;
          }
        });
    },
    previewVideo() {
      if (!this.form.workContent) {
        this.$message.error("请上传视频！");
        return;
      }
      this.dialogVisible = true;
      // this.dataListLoading = true
      setTimeout(() => {
        this.initVideo();
      }, 0);
    },
    initVideo() {
      let that = this;
      let width = this.getParams("width");
      let height = this.getParams("height");
      // console.log(width);
      var player = new TcPlayer("abc", {
        m3u8:
          "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
          this.form.workContent, //请替换成实际可用的播放地址
        autoplay: false, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: { style: "stretch", src: that.imageUrl },
        width: width || "100%",
        height: height || "100%",
        //playbackRates: [0.5,1,1.2,1.5,2], // 设置变速播放倍率选项，仅 HTML5 播放模式有效
        // controls:'system', // default 显示默认控件，none 不显示控件，system 移动端显示系统控件 备注：如果需要在移动端使用系统全屏，就需要设置为system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式例子
        pausePosterEnabled: true,
        volume: 0.5,
        systemFullscreen: true,
        x5_orientation: 0,
      });
      document.getElementsByClassName("vcp-error-tips")[0].style.fontSize =
        "14px"; // 错误码提示语字体大小，因引入了rem，默认字体大小为100px.8
    },
    getParams(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    handleAvatarSuccess(res, file) {
      this.upLoading = false;
      console.log(res, "success res");
      // this.form.workContent = res.result.filePath;
      this.form.workContent = res.result.path;
      this.form.mvUrl = res.result.path;
      this.imageUrl =
        "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
        this.form.mvUrl +
        "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
    },
    handleAvatarSuccess2(res, file) {
      this.upLoading = false;

      console.log(res, "success res");
      // this.form.workContent = res.result.filePath;
      this.form.workContent = res.result.path;

      this.form.mvUrl = res.result.path;
      // this.imageUrl =
      //   'https://aibiboat.oss-cn-shanghai.aliyuncs.com/' +
      //   this.form.mvUrl +
      //   "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
    },
    beforeAvatarUpload(file) {
      // this.upLoading = true

      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let type = lastName.toLowerCase();
      if (type != ".mp4") {
        this.$message.error("视频格式只支持MP4格式");
        return;
      } else {
        this.upLoading = true;
        let instance = axios.create({
          // timeout: 200000,
        });
        let url = this.$global.baseURL + "/aliyun/oss/upload";
        const formData = new FormData();
        formData.append("file", file);
        formData.append("isToken", 1);
        instance
          .post(url, formData)
          .then(({ data }) => {
            this.$message({
              message: "文件上传成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.upLoading = false;
                if (data && data.result && data.result.path) {
                  this.handleAvatarSuccess(data, file);
                }
              },
            });
          })
          .catch(() => {
            this.upLoading = false;
          });
      }
    },
    beforeAvatarUpload2(file) {
      // this.upLoading = true

      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let type = lastName.toLowerCase();
      if (type != ".mp3") {
        this.$message.error("音频格式只支持MP3格式");
        return;
      } else {
        this.upLoading = true;
        let instance = axios.create({
          // timeout: 200000,
        });
        let url = this.$global.baseURL + "/aliyun/oss/upload";
        const formData = new FormData();
        formData.append("file", file);
        formData.append("isToken", 1);
        instance
          .post(url, formData)
          .then(({ data }) => {
            this.$message({
              message: "文件上传成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.upLoading = false;
                if (data && data.result && data.result.path) {
                  this.handleAvatarSuccess2(data, file);
                }
              },
            });
          })
          .catch(() => {
            this.upLoading = false;
          });
      }
    },
    back() {
      this.$router.back();
    },
    async init(id) {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/missionaryWork/getMissionaryWork"),
        method: "post",
        data: { stringParam1: id },
      });
      if (res.status) {
        this.form = res.data;
        this.dataListLoading = false;
        // this.form.workType = [res.data.workType,res.data.workTypeSec,res.data.workTypeThree,res.data.workTypeFour,res.data.workTypeFive]
        let arr = [];
        if (res.data.workType) {
          arr.push(res.data.workType);
        }
        if (res.data.workTypeSec) {
          arr.push(res.data.workTypeSec);
        }
        if (res.data.workTypeThree) {
          arr.push(res.data.workTypeThree);
        }
        if (res.data.workTypeFour) {
          arr.push(res.data.workTypeFour);
        }
        if (res.data.workTypeFive) {
          arr.push(res.data.workTypeFive);
        }
        this.form.workType = arr;
        if (this.form.workContentUrl) {
          this.radio = "2";
          this.form.mvUrl =
            "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
            res.data.workContent;
          this.imageUrl =
            "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
            res.data.workContentUrl +
            "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
        }
      }
    },
    async getDiseasesList() {
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '基础病种',
		
		},
       
      });
      // console.log(res,'res')
      this.diseasesList = res.data;
    },
    async getTypeList() {
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/dict/getSysDicts"),
        method: "get",
        params: {
          type: "宣教分类",
          pageSize: 999,
          teamsId,
        },
      });
      // console.log(res,'res')
      this.typeList = res.data;
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].isEdit = false;
        res.data[i].children = res.data[i].map.list;
        // console.log(res.data[i].children,'res.data[i].children');
        for (var k = 0; k < res.data[i].children.length; k++) {
          if (
            res.data[i].children[k].map &&
            res.data[i].children[k].map.list.length
          ) {
            res.data[i].children[k].children = res.data[i].children[k].map.list;
            for (var j = 0; j < res.data[i].children[k].children.length; j++) {
              if (
                res.data[i].children[k].children[j].map &&
                res.data[i].children[k].children[j].map.list.length
              ) {
                res.data[i].children[k].children[j].children =
                  res.data[i].children[k].children[j].map.list;
                for (
                  var m = 0;
                  m < res.data[i].children[k].children[j].children.length;
                  m++
                ) {
                  if (
                    res.data[i].children[k].children[j].children[m].map &&
                    res.data[i].children[k].children[j].children[m].map.list
                      .length
                  ) {
                    res.data[i].children[k].children[j].children[m].children =
                      res.data[i].children[k].children[j].children[m].map.list;
                  } else {
                    res.data[i].children[k].children[j].children[
                      m
                    ].children = undefined;
                  }
                }
              } else {
                res.data[i].children[k].children[j].children = undefined;
              }
            }
          } else {
            res.data[i].children[k].children = undefined;
          }
        }
      }
      this.options = res.data;
    },
    async submit() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      // const team = JSON.parse(localStorage.getItem('currentTeam'))
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const form = this.form;
          let obj = {
            workName: form.workName,
            workType: form.workType[0],
            workTypeSec: form.workType[1],
            workTypeThree: form.workType[2],
            workTypeFour: form.workType[3],
            workTypeFive: form.workType[4],
            workEnertyType: form.enertyId,
            workContent: form.workContent,
            // hospitalId:user.deptPid,
            // deptId:user.deptId,
            hospitalId: teamsId,
            // teamsId:teamsId,
            deptId: enertyId,
            type: form.type,
          };
          if (form.mvUrl) {
            obj.workContentUrl = form.mvUrl;
          }
          console.log(obj);
          if (form.id) {
            obj.id = form.id;
          }
          console.log(obj, "obj");
          const { data: res } = await this.$httpAes({
            url: this.$http.adornUrl(
              obj.id ? "/missionaryWork/update" : "/missionaryWork/add"
            ),
            method: "post",
            data: obj,
          });
          console.log(res);
          if (res.status) {
            this.$message.success(res.msg);
            this.$router.push("/zykgl/mission");
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mission-add {
}
</style>